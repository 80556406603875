<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>

      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="showAddInvoiceForm"
          >
            Add Invoice
          </b-button>
        </b-col>

        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(index)="data">
              {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index +
                1)
              }}
            </template>

            <template #cell(active)="data">
              {{ data.item.active === 1 ? "Yes" : "No" }}
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  style="margin-right: 2px;"
                  size="sm"
                  @click="showEditInvoice(row.item)"
                >
                  Edit
                </b-button>

                <b-button
                  size="sm"
                  @click="showInvoiceDetails(row.item)"
                >
                  Show Invoice
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-card>
    <b-modal
      id="modal-show-invoice"
      scrollable
      no-close-on-backdrop
      size="lg"
      title="Invoice Details"
    >

      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-start"
        >
          <div>
            <h5>{{ invoiceDetails.invoiceDealerName }}</h5>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <div>
            <h4>Invoice No: <span>{{ invoiceDetails.invoiceNumber }}</span></h4>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col
          cols="12"
          sm="3"
          class="d-flex"
          style="background-color: black;"
        >
          <div>
            <h5 style="color:white;">
              Bill To:
            </h5>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex"
        >
          <div>
            <h6>Name: <span>{{ invoiceDetails.invoiceName }}</span></h6>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex"
        >
          <div>
            <h6>Address: <span>{{ invoiceDetails.invoiceAddress }}</span></h6>
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex"
        >
          <div>
            <h6>Date: <span>{{ invoiceDetails.invoiceDate }}</span></h6>
          </div>
        </b-col>
      </b-row>
      <div style="margin-top: 50px;">
        <b-card style="border: 1px solid black;">
          <b-card-text>
            <div>
              <b-table
                striped
                hover
                :items="items"
                :fields="tableInvoiceDetails.fields"
              />

            </div>

          </b-card-text>
        </b-card>
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-add-invoice"
      scrollable
      no-close-on-backdrop
      size="xl"
      :title="modalTitle"
      @ok="handleOk"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row class="mb-2">
            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="invoice_number"
                vid="invoice_number"
              >
                <b-form-group>
                  <label for="invoice_number">Invoice Number</label>
                  <b-input
                    id="invoice_number"
                    v-model="invoice.invoice_number"
                    type="number"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    placeholder="enter invoice number"
                    autocomplete="off"
                    :disabled="editMode ? '' : disabled "
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="invoice_date"
                vid="invoice_date"
                rules="required"
              >
                <b-form-group>
                  <label for="invoice_date">Invoice Date</label>
                  <b-input
                    id="invoice_date"
                    v-model="invoice.invoice_date"
                    type="date"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    placeholder="enter invoice date"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                name="dealer"
                vid="dealer"
                rules="required"
              >
                <b-form-group>
                  <label>Dealer</label>
                  <v-select
                    v-model="selectedDealer"
                    :options="dealers"
                    placeholder="Select Dealer"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="farmers_name"
                vid="farmers_name"
                rules="required"
              >
                <b-form-group>
                  <label for="farmers_name">Farmers Name</label>
                  <b-input
                    id="farmers_name"
                    v-model="invoice.farmers_name"
                    type="text"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    placeholder="enter farmers name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="address"
                vid="address"
              >

                <div class="form-group">
                  <label for="address">Address</label>
                  <b-input
                    id="address"
                    v-model="invoice.address"
                    type="text"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    placeholder="enter address"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>

              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="contact_number"
                vid="contact_number"
              >

                <div class="form-group">
                  <label for="contact_number">Contact Number</label>
                  <b-input
                    id="contact_number"
                    v-model="invoice.contact_number"
                    type="number"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    placeholder="enter contact number"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col cols="12">
              <div />
              <b-button
                type="button"
                variant="success"
                @click="addItem"
              >
                Add Product
              </b-button>
            </b-col>
            <b-col
              cols="12"
              sm="4"
            >
              <div class="form-group">
                <label>Product Category</label>
                <v-select
                  v-model="product.product_category_id"
                  :options="product_categories"
                />
              </div>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <div class="form-group">
                <label>Product</label>
                <v-select
                  v-model="product.product_id"
                  :options="products"
                />
              </div>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="quantity"
                vid="quantity"
                rules="numeric"
              >

                <div class="form-group">
                  <label for="quantity">Quantity</label>
                  <b-input
                    id="quantity"
                    v-model="product.quantity"
                    type="number"
                    :class="` form-control-sm ${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="enter quantity"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>

              </ValidationProvider>
            </b-col>
          </b-row>

          <div>
            <b-table
              striped
              hover
              :items="items"
              :fields="tableProductDetails.fields"
            >
              <template #cell(action)="row">
                <b-button
                  size="sm"
                  @click="removeSaleorderItem(row.item)"
                >
                  remove
                </b-button>
              </template>
            </b-table>

          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ editMode ? 'Update Invoice' : 'Save Invoice' }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { ListService, AxiosError, InvoiceService } from '@/services'

export default {
  name: 'UserInvoice',

  middleware: ['auth', 'user'],

  data () {
    return {
      editMode: false,
      isBusy: false,
      invoice: {
        invoice_number: null,
        invoice_date: '',
        farmers_name: '',
        dealer_id: '',
        address: '',
        contact_number: null,
        invoice_id: null
      },
      invoiceDetails: {
        invoiceNumber: null,
        invoiceDealerName: '',
        invoiceName: '',
        invoiceAddress: '',
        invoiceDate: '',
        contact_number: null
      },
      dealers: [],
      selectedDealer: [],
      product_categories: [],
      products: [],
      product: {
        product_category_id: '',
        product_id: '',
        quantity: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'invoice_number', sortable: true },
          { key: 'invoice_date', sortable: true },
          { key: 'dealer_name', sortable: true },
          { key: 'farmers_name', sortable: true },
          { key: 'address', sortable: true }
        ]
      },
      tableProductDetails: {
        fields: [
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'quantity',
            sortable: true
          },
          'action'
        ]
      },
      tableInvoiceDetails: {
        fields: [
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'quantity',
            sortable: true
          }
        ]
      },
      items: []
    }
  },
  computed: {
    modalTitle () {
      return this.editMode ? 'Edit Invoice' : 'Add New Invoice'
    },
    filterDealers () {
      return [{ value: 'All', label: 'All' }].concat(this.dealers)
    }
  },
  watch: {
    'product.product_category_id.value' (value) {
      this.getProduct(value)
    },
    'selectedDealer' ({ value }) {
      this.invoice = { ...this.invoice, dealer_id: value }
    }

  },
  metaInfo () {

  },
  mounted () {
    core.index()
    this.getDealers()
    this.getProductCategory()
  },

  methods: {

    async myProvider (ctx) {
      try {
        const { data } = await InvoiceService.getInvoices(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async showInvoiceDetails (item) {
      await InvoiceService.getInvoiceProduct(item.invoice_id).then(({ data }) => {
        this.items = data
        this.invoiceDetails.invoiceNumber = item.invoice_number
        this.invoiceDetails.invoiceDealerName = item.dealer_name
        this.invoiceDetails.invoiceName = item.farmers_name
        this.invoiceDetails.invoiceAddress = item.address
        this.invoice.dealer_id = item.dealer_id
        this.invoiceDetails.invoiceDate = item.invoice_date
        this.invoice.invoice_id = item.invoice_id
      })
      this.$bvModal.show('modal-show-invoice')
    },

    handleOk (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.editMode) {
        this.onconfirmPutSubmit()
      } else {
        this.onConfirmSubmit()
      }
    },

    async showEditInvoice (item) {
      await InvoiceService.getInvoiceProduct(item.invoice_id).then(({ data }) => {
        this.editMode = true
        this.items = data
        this.invoice.invoice_number = item.invoice_number
        this.invoice.dealer_id = item.dealer_id
        this.invoice.farmers_name = item.farmers_name
        this.invoice.address = item.address
        this.invoice.invoice_date = item.invoice_date
        this.invoice.invoice_id = item.invoice_id
        this.selectedDealer = { value: item.dealer_id, label: item.dealer_name }
      })

      this.$bvModal.show('modal-add-invoice')
    },

    showAddInvoiceForm () {
      this.$bvModal.show('modal-add-invoice')
    },
    async getDealers () {
      await ListService.getDealers().then(({ data }) => {
        this.dealers = data.map(({ id, dealer_name }) => {
          return { value: id, label: dealer_name }
        })
      })
    },
    async getProductCategory () {
      await ListService.getProductCategories().then(({ data }) => {
        data.forEach(item => {
          this.product_categories.push({
            value: item.id,
            text: item.product_category_name,
            label: item.product_category_name
          })
        })
      })
    },
    async getProduct (productCategories) {
      this.products = []
      await ListService.getProducts(`product_category_id=${productCategories}`).then(({ data }) => {
        data.forEach(item => {
          this.products.push({
            value: item.id,
            text: item.product_name,
            label: item.product_name
          })
        })
      })

      // if (this.products.length > 0) {
      //   this.product.product_id = this.product_name[0].value
      // }
    },
    addItem () {
      const productCategoryId = this.product.product_category_id
      const productId = this.product.product_id
      const quantity = this.product.quantity

      if (productCategoryId === '' || productId === '' || quantity === '' || quantity === 0 || quantity === '0') {
        this.$swal.fire({
          icon: 'warning',
          title: 'Validation Error',
          text: 'Oppss! There were problem with your inputs.',
          confirmButtonColor: '#FF2929',
          confirmButtonText: 'Dismiss',
          size: 'sm'
        })
        return false
      }

      const isExisting = this.items?.filter(
        el => el.product_id === productId.value
      )

      if (isExisting.length) {
        const newData = this.items.map(el => {
          if (
            el.product_id === productId.value
          ) {
            el.quantity = Number(el.quantity) + Number(this.product.quantity)
            return el
          }

          return el
        })

        this.items = newData
        this.product.product_category_id = ''
        this.product.product_id = ''
        this.product.quantity = 0
      } else {
        this.items.push({
          product_category_name: productCategoryId.label,
          product_category_id: productCategoryId.value,
          product_name: productId.label,
          product_id: productId.value,
          quantity: this.product.quantity
        })

        this.product.product_category_id = ''
        this.product.product_id = ''
        this.product.quantity = 0
      }
    },

    removeSaleorderItem (item) {
      const oldItem = this.items

      const newItem = oldItem.filter(el => {
        return el.product_name !== item.product_name
      })

      this.items = newItem
      // this.items.splice(item,1);
    },

    async onConfirmSubmit () {
      // bvModalEvt.preventDefault()
      if (this.items.length === 0) {
        this.$swal.fire({
          icon: 'warning',
          title: 'Validation Error',
          text: 'Oppss! There were problem with your inputs.',
          confirmButtonColor: '#FF2929',
          confirmButtonText: 'Dismiss'
        })
      } else {
        await this.$refs.form.validate().then(async success => {
          if (success) {
            if (!this.editMode) {
              this.$swal.fire({
                title: 'Are you sure want to add this invoice ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Submit',
                cancelButtonColor: '#FF2929',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  return this.onSubmitPostForm()
                },
                allowOutsideClick: () => !this.$swal.isLoading()
              })
            }
          } else {
            this.$swal.fire({
              icon: 'warning',
              title: 'Validation Error',
              text: 'Oppss! There were problem with your inputs.',
              confirmButtonColor: '#FF2929',
              confirmButtonText: 'Dismiss'
            })
          }
        })
      }
    },
    async onconfirmPutSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            title: 'Do you really want to save any changes you made?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Confirm',
            cancelButtonColor: '#FF2929',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onPutSubmitForm()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$swal.fire({
            icon: 'warning',
            title: 'Validation Error',
            text: 'Oppss! There were problem with your inputs.',
            confirmButtonColor: '#FF2929',
            confirmButtonText: 'Dismiss'
          })
        }
      })
    },

    async onSubmitPostForm () {
      const obj = {
        invoice_number: this.invoice.invoice_number,
        invoice_date: this.invoice.invoice_date,
        dealer_id: this.invoice.dealer_id,
        farmers_name: this.invoice.farmers_name,
        address: this.invoice.address,
        product_details: this.items,
        contact_number: this.invoice.contact_number
      }
      this.isBusy = true
      InvoiceService.post(obj).then(response => {
        this.$bvModal.hide('modal-add-invoice')
        this.isBusy = false
        this.$swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.modalShow = false
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
            this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger'
            })
          }
        }
        this.isBusy = false
      })
    },
    async onPutSubmitForm () {
      const obj = {
        invoice_number: this.invoice.invoice_number,
        invoice_date: this.invoice.invoice_date,
        dealer_id: this.invoice.dealer_id,
        farmers_name: this.invoice.farmers_name,
        address: this.invoice.address,
        product_details: this.items,
        invoice_id: this.invoice.invoice_id
      }
      this.isBusy = true
      InvoiceService.put(obj).then(response => {
        this.$bvModal.hide('modal-add-invoice')
        this.isBusy = false
        this.$swal.fire({
          icon: 'info',
          title: 'Info',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.modalShow = false
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
            this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger'
            })
          }
        }
        this.isBusy = false
      })
    }

  }
}
</script>
